import store from "../store"
import { init_cart } from "./Cart";
import {projectFirestore} from '../firebase/config'

const freeze = async (time) => {
    await new Promise((resolve) => {
        setTimeout(() => {
            resolve()
        }, time);
    })
}
const init_catalog = async () => {
    store.state.loader = true;
    await freeze(2000)
    
    await store.dispatch("get_products");
    await store.dispatch('get_departments'); 
    await store.dispatch('get_sales');
    
    console.log('Catalog - Done.');
    
    init_cart()

    store.state.loader = false;
    await store.dispatch('get_promotions');

}

const init_flayers = async()=>{
    const current_date = new Date(new Date().toISOString().split('T')[0])
    if(store.state.client_cart.assortment){
        const docs = await projectFirestore.collection('Flayers')
        .where('asortment','array-contains',store.state.client_cart.assortment).get()
        if(!docs.empty){
            const res = docs.docs.map(doc=>doc.data()).filter(f=>current_date >= new Date(f.start_date) && current_date <= new Date(f.end_date))
            return res
        }
        return null
    }
    return null
}

export{
    init_catalog,
    init_flayers,
}